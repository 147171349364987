.amplify-tabs {
  display: none !important;
}

#root {
  background-color: #141518;
}

html,
body,
#root {
  height: 100%;
}
